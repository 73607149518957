.search {
  position: relative;
  display: flex;
  align-content: normal;
  width: 100%;

  .menu {
    position: absolute;
    top: 100%;
    box-shadow: #00000061 0 3px 3px;
    min-width: 340px;
    width: 23vw;
    &.closed {
      display: none;
    }

    .info {
      padding: 12px;
      font-family: Lato, sans-serif;
      font-size: 16px;
      color: #000000DE;

      &.empty {
        color: #57616B;
      }
    }
    .search-item:not(:last-child) {
      border-bottom: #DDDFE1 1px solid;
    }
  }

  .search-input {
    width: 100%;
    color: #101e2c !important;
    .MuiInputBase-input {
      font-family: Eesti, sans-serif;
      font-size: 18px;
      width: 100%;
    }

    .input {
      width: 100%;
    }

    .search-icon {
      color: #101E2C66;
      margin-right: 10px;
    }
    &:focus-within .search-icon {
      color: #00B796;
    }
  }
}

