@import "~shared/src/styles/mixins";

.notification {
  padding: 0px 23px 0px 20px !important;

  height: 100%;



  &.inactive{
    @include normal-lato-regular;
  }
  &.active {
    @include regular-lato-bold;
  }

  .notification-line {
    display: grid;
    align-items: center;
    grid-template-columns: 66px 280px 92px;
    white-space: nowrap;

    .notification__target-reference {
      grid-column: 1;
    }
    
    .notification__description {
      grid-column: 2;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #101E2C;
      font-size: 14px;
      margin: 3px 0;
    }
  
    .notification__date {
      grid-column: 3;
      color: #57616BCC;
      @include small-lato-regular;
    }
    .MuiGrid-item {
      display: flex;
      align-items: center;
    }
  }

}