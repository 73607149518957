#menu-notifications {
  font-family: Lato, sans-serif;
  margin-top: 8px;

  .menu-notifications__container {
    max-width: 479px;
    width: 479px;
    box-shadow: inset 0px 3px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%);
  }

  .menu-notifications__notification {
    display: flex;
    align-items: center;
    height: 37px;
    border-bottom: #DDDFE1 1px solid !important;

    &:first-child{
      border-top: #DDDFE1 1px solid;
    }
  }
  .menu-notifications__notification.summary {
    padding: 0px 20px;
    display: flex;
    // margin-top: -8px;
    align-items: center;
    height: 37px;
  }
  .MuiPaper-root {
    max-width: 40%;
    box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%);
    border-radius: 0 0 8px 8px;
  }

  .menu-notifications__notification.showAll{
    cursor: pointer;
    font-family: Lato, sans-serif;
    color: #00B796;
    font-size: 12px;
    text-align: center;
    font-weight: bold;
    padding: 12px 0;
    border-bottom: none;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 39px;
  }
}