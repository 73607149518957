@import url(https://fonts.googleapis.com/css2?family=Lato&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face{font-family:"Eesti";src:local("GT-Eesti-Display-Medium"),url(/static/media/GT-Eesti-Display-Medium.dfbc3bd0.ttf) format("truetype"),url(/static/media/GT-Eesti-Display-Medium.8aa9dcad.woff) format("woff");font-weight:normal;font-style:normal}fieldset{border-width:2px !important}.MuiSnackbar-root .MuiSnackbarContent-root{font-family:Lato,sans-serif;font-size:16px;font-weight:normal}body{margin:0;padding:0;font-family:-apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;background:#fdfdfd;min-height:100%}#root{min-height:100%;height:100%}code{font-family:source-code-pro,Menlo,Monaco,Consolas,"Courier New",monospace}.clickable{cursor:pointer}.button-form{text-align:right}h3{margin-top:16px;color:rgba(0,0,0,.65);font-size:14px;font-weight:bold}.flex{display:-webkit-flex;display:flex}.felx-center{display:-webkit-flex;display:flex;-webkit-justify-content:center;justify-content:center}.flex-center-left{display:-webkit-flex;display:flex;-webkit-justify-content:flex-start;justify-content:flex-start;-webkit-align-items:center;align-items:center}.flex-end{display:-webkit-flex;display:flex;-webkit-justify-content:flex-end;justify-content:flex-end}.card-header-reverse{background-color:gray;color:#fff}.padded-large{padding:35px}.bold{font-weight:bold}.text-center{text-align:center}.icon-big{font-size:50px}.appBarIcon{color:#101e2c;margin-right:0;margin-left:15px;border-radius:15px}.fullWidth{width:100%}
.loader-overlay{position:absolute;top:0;bottom:0;left:0;right:0;display:flex;justify-content:center;align-items:center;pointer-events:none}
body{background-color:#e5e9e9}.MuiToolbar-regular{height:64px}.main-content{width:100%}.main-content>*:not(.list-page){max-width:1200px;width:100%}.list-page{max-width:100%;width:100%}
.menu-arrow-container{border-top:1px solid #1b2836;border-radius:0 !important;width:100%;overflow:hidden !important;margin-left:0 !important;cursor:pointer !important;position:absolute !important;top:0;bottom:0}.menu-arrow-container .menu-arrow-block.closed .closed{opacity:0}.menu-arrow-container .menu-arrow-block .menu-arrow{transition:ease-in .5s !important;color:#b7bcc0;position:relative}.menu-arrow-container .menu-arrow-block .menu-arrow .menu-arrow-button{position:absolute;left:.9rem;top:.28rem;transform:scale(0.85)}.menu-arrow-container .menu-arrow-block .menu-arrow .menu-arrow-button-outline{position:absolute;left:.53rem;top:-1px;transform:scale(0.8)}.menu-arrow-container .menu-arrow-block .text{transition:ease-in .5s !important;color:#b7bcc0;font-size:11pt}.menu-arrow-container>.MuiIconButton-label{display:block}.menu-arrow-container:hover .menu-arrow{color:#fff !important}.menu-arrow-container:hover .text{color:#fff !important}
.MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button.Mui-selected::before{content:"";background-clip:content-box;border-radius:10px;position:absolute;width:calc(100% - 24px);height:calc(100% - 24px);top:12px;left:12px}.menu-list{margin-top:20px !important;overflow:hidden}.menu-list .menu-list-item{font-family:"Eesti",sans-serif !important;height:45px;margin-top:5px;padding-top:1px;padding-bottom:0px;margin-left:12px;border-radius:10px;width:calc(100% - 24px)}.menu-list .menu-list-item.closed .menu-icon{margin-left:-3px}.menu-list .menu-list-item.closed .menu-text{max-width:0}.menu-list .menu-list-item .menu-icon{padding-top:4px;transition:all .2s ease-in-out}.menu-list .menu-list-item .menu-container{display:flex;align-items:center;height:100%}.menu-list .menu-list-item .menu-text{color:#798188;transition:all .2s;font-family:"Eesti",sans-serif;font-size:16px;display:block;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;max-width:500px;margin-left:17px}.menu-list .menu-list-item .menu-text.selected{color:#fff !important}.menu-list .menu-list-item:hover{background-color:rgba(255,255,255,.12) !important}.menu-list .menu-list-item:hover .menu-text{color:#fff !important}.menu-list .menu-list-item.selected{background-color:rgba(255,255,255,.12) !important}.MuiListItem-root.Mui-selected,.MuiListItem-root.Mui-selected:hover{position:relative}
.app-version{position:absolute;bottom:2px;font-family:Lato,sans-serif;font-size:12px;font-weight:normal;color:gray;width:95%;margin:auto;overflow:hidden;text-align:center}
.notification-button{border-radius:10px !important}.notification-button .notification-badge .MuiBadge-colorPrimary{color:#fff}.notification-button:hover{background-color:rgba(0,0,0,.07) !important}.notification-button.opened{background-color:rgba(0,0,0,.07) !important;color:#00b796}.notification-button.opened .bell-opened{filter:brightness(0) saturate(100%) invert(54%) sepia(25%) saturate(5286%) hue-rotate(134deg) brightness(94%) contrast(101%)}
.notification{padding:0px 23px 0px 20px !important;height:100%}.notification.inactive{font-family:Lato,sans-serif;font-size:14px;font-weight:normal}.notification.active{font-family:Lato,sans-serif;font-size:14px;font-weight:bold}.notification .notification-line{display:grid;align-items:center;grid-template-columns:66px 280px 92px;white-space:nowrap}.notification .notification-line .notification__target-reference{grid-column:1}.notification .notification-line .notification__description{grid-column:2;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;color:#101e2c;font-size:14px;margin:3px 0}.notification .notification-line .notification__date{grid-column:3;color:#57616BCC;font-family:Lato,sans-serif;font-size:12px;font-weight:normal}.notification .notification-line .MuiGrid-item{display:flex;align-items:center}
#menu-notifications{font-family:Lato,sans-serif;margin-top:8px}#menu-notifications .menu-notifications__container{max-width:479px;width:479px;box-shadow:inset 0px 3px 5px -3px rgba(0,0,0,.2),0px 8px 10px 1px rgba(0,0,0,.14)}#menu-notifications .menu-notifications__notification{display:flex;align-items:center;height:37px;border-bottom:#dddfe1 1px solid !important}#menu-notifications .menu-notifications__notification:first-child{border-top:#dddfe1 1px solid}#menu-notifications .menu-notifications__notification.summary{padding:0px 20px;display:flex;align-items:center;height:37px}#menu-notifications .MuiPaper-root{max-width:40%;box-shadow:0 5px 5px -3px rgba(0,0,0,.2),0px 8px 10px 1px rgba(0,0,0,.14);border-radius:0 0 8px 8px}#menu-notifications .menu-notifications__notification.showAll{cursor:pointer;font-family:Lato,sans-serif;color:#00b796;font-size:12px;text-align:center;font-weight:bold;padding:12px 0;border-bottom:none;display:flex;align-items:center;justify-content:center;height:39px}
.search-item{font-family:Lato,sans-serif;font-weight:bold;background-color:#fff;padding:12px;color:#000000DE;align-items:center}.search-item>*{align-items:center;display:flex}.search-item:hover{background-color:#dddfe1}.search-item .type{font-size:10px;text-transform:uppercase}.search-item .reference{font-size:16px}.search-item .details{font-weight:normal;font-size:14px;text-align:right}
.search{position:relative;display:flex;align-content:normal;width:100%}.search .menu{position:absolute;top:100%;box-shadow:#00000061 0 3px 3px;min-width:340px;width:23vw}.search .menu.closed{display:none}.search .menu .info{padding:12px;font-family:Lato,sans-serif;font-size:16px;color:#000000DE}.search .menu .info.empty{color:#57616b}.search .menu .search-item:not(:last-child){border-bottom:#dddfe1 1px solid}.search .search-input{width:100%;color:#101e2c !important}.search .search-input .MuiInputBase-input{font-family:Eesti,sans-serif;font-size:18px;width:100%}.search .search-input .input{width:100%}.search .search-input .search-icon{color:#101E2C66;margin-right:10px}.search .search-input:focus-within .search-icon{color:#00b796}
#menu-notifications .MuiList-padding{padding-bottom:0 !important;padding-top:0 !important}.app-bar{padding-left:0 !important}.app-bar .MuiGrid-container{height:100%}.app-bar .logo-container{display:-webkit-flex;display:flex;-webkit-align-items:center;align-items:center;-webkit-justify-content:center;justify-content:center;width:240px}.app-bar .logo-container.closed{width:73px;-webkit-flex-basis:initial;flex-basis:initial}.app-bar .logo-container .desktop{display:-webkit-flex;display:flex}.app-bar .logo-container .mini{display:none}.app-bar .search-container{display:-webkit-flex;display:flex;-webkit-align-items:center;align-items:center;-webkit-flex-grow:1;flex-grow:1}.app-bar .profile-container,.app-bar .buttons-container{display:-webkit-flex;display:flex;-webkit-align-items:center;align-items:center;height:100%}.app-bar .MuiGrid-item{height:100%}@media(max-width: 960px){.app-bar .logo-container .desktop{display:none}.app-bar .logo-container .mini{display:-webkit-flex;display:flex}}
.error-page{height:100%;display:flex;justify-content:center;align-items:center}.error-page .error-container{display:flex;width:33%;justify-content:center;align-items:center;height:30%;font-size:18px;font-family:Lato,sans-serif;font-weight:bold;border-radius:8px;background-color:#fff}
.password-field .MuiFormLabel-root{font-family:Lato,sans-serif;font-size:16px;font-weight:normal}.password-field .MuiInputLabel-animated{z-index:1}.password-field .password-field-input{font-family:Lato,sans-serif;font-size:16px;font-weight:normal}
.username-field .MuiFormLabel-root{font-family:Lato,sans-serif;font-size:16px;font-weight:normal}.username-field .MuiInputLabel-animated{z-index:1}.username-field .username-field-input{font-family:Lato,sans-serif;font-size:16px;font-weight:normal}
.login-view{display:-webkit-flex;display:flex;-webkit-flex-direction:column;flex-direction:column;-webkit-justify-content:center;justify-content:center;-webkit-align-items:center;align-items:center;min-height:100%}.login-view .layout-panel{width:300px;background:#24212c;box-shadow:0 1px 5px rgba(0,0,0,.5);padding-top:24px}.login-view .logo{display:-webkit-flex;display:flex;-webkit-justify-content:center;justify-content:center;-webkit-align-items:center;align-items:center;width:160px;height:160px;border-radius:50%}.login-view .logo img{width:100%}
